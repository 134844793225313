<template>
  <div>
    <div v-show="!aa">
      <div class="apply_box_top">
        <div class="apply_box_top_left " :class="[classChange? 'class_change2' : 'class_change1']">
          <div class="apply_box_total apply_bottom" @click="buttonChange1()">
            <div class="apply_box_total_icon">
              <i class="iconfont icon-walletY-fill"></i>
            </div>
            <div class="apply_box_total_text">
              <span>已放款总额(元)</span>
              <el-popover placement="top" trigger="hover" :content="supplierNum | NumFormat" popper-class="popper_class_account">
                <div slot="reference" class="text_overflow1">{{supplierNum | NumFormat}}</div>
              </el-popover>
            </div>
          </div>
          <div class="apply_box_total" @click="buttonChange2()">
            <div class="apply_box_total_icon">
              <i class="iconfont icon-dingdan"></i>
            </div>
            <div class="apply_box_total_text apply_box_total_text_special">
              <span>总投资单数(单)</span>
              <span class="select">{{coreEnterPriseNum}}</span>
              <span>/</span>
              <span>{{alreadyEnterPriseNum}}</span>
            </div>
            <!-- <div class="apply_box_total_text">
              <span>总投资单数(单)</span>
              <span>{{coreEnterPriseNum}}</span>
            </div> -->
          </div>
        </div>
        <div class="apply_box_top_right">
          <div ref="echarts1" class="echarts_box"></div>
        </div>
      </div>
      <div class="investor_workbench_table_title_total flex_center_around_box">
        <div class="flex_box">
          <div class="investor_workbench_table_title_total_tit">最高投资金额(元):</div>
          <div class="workbench_table_title_total_button">{{highInvestmentMoney | NumFormat}}</div>
          <div class="vertical_line"></div>
        </div>
        <div class="flex_box">
          <div class="investor_workbench_table_title_total_tit">最低投资金额(元):</div>
          <div class="workbench_table_title_total_button">{{lowInvestmentMoney | NumFormat}}</div>
          <div class="vertical_line"></div>
        </div>
        <div class="flex_box">
          <div class="investor_workbench_table_title_total_tit">平均投资金额(元):</div>
          <div class="workbench_table_title_total_button">{{averageInvestmentMoney | NumFormat}}</div>
          <div class="vertical_line"></div>
        </div>
        <div class="flex_box">
          <div class="investor_workbench_table_title_total_tit">平均投资回报(元):</div>
          <div class="workbench_table_title_total_button">{{averageInvestmentReturn | NumFormat}}</div>
          <div class="vertical_line"></div>
        </div>
      </div>
      <div class="table_common">
        <div class="search_flex_box">
          <div class="search_condition search_condition_right">
            <el-form ref="financingForm" :model="financingForms1">
              <el-form-item label="融资编号:" class="financing_number financing_border" prop="financingNum">
                <el-input v-model="financingForms1.financingNum" @input="searchInput" maxlength="20">
                  <template slot="prepend">FN</template>
                </el-input>
              </el-form-item>
              <el-form-item label="融资企业名称:" prop="financingName">
                <el-input v-model="financingForms1.financingName"></el-input>
              </el-form-item>
              <el-form-item label="融资企业类型:" prop="financingEnterpriseType">
                <el-select v-model="financingForms1.financingEnterpriseType" placeholder="" class="select_class">
                  <el-option v-for="item in  companyState" :key="item.index" :label="item.value" :value="item.codeState"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="放款日期:" prop="lendTime">
                <el-date-picker v-model="financingForms1.lendTime" prefix-icon="iconfont icon-riqi" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                  value-format="yyyy-MM-dd" :editable="false">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="还款日期:" class="data_item" prop="repaymentTime">
                <el-date-picker v-model="financingForms1.repaymentTime" prefix-icon="iconfont icon-riqi" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                  value-format="yyyy-MM-dd" :editable="false">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="账款状态:" class="data_item" prop="state">
                <el-select v-model="financingForms1.state" placeholder="" class="select_class">
                  <el-option v-for="item in  accountsState" :key="item.index" :label="item.value" :value="item.codeState"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="search_boxs flex_center_between_box">
            <el-tooltip popper-class="search" effect="light" content="搜索" placement="top">
              <div class="search_button_box" @click="searchForm()">
                <i class="iconfont icon-10sousuo"></i>
              </div>
            </el-tooltip>
            <el-tooltip popper-class="search" effect="light" content="重置" placement="top">
              <div class="reset_button_box" @click="resetButton()">
                <i class="iconfont icon-shuaxin"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="table_box">
          <div class="table_box_top flex_center_between_box">
            <div class="table_box_top_title">我的投资</div>
            <div class="flex_center_end_box">
              <div class="search_button button data_export">数据导出</div>
            </div>
          </div>
          <div class="table_box_bottom">
            <el-table ref="treeTable" :data="tableData" stripe border style="width: 100%">
              <el-table-column label="融资编号" prop="financingNum" align="center" :resizable="false" :width="width180" />
              <el-table-column label="融资企业名称" prop="financingName" align="left" header-align="center" :width="nameWidth" show-overflow-tooltip :resizable="false">
                <template slot-scope="scope">
                  <div class="link" @click="detailIndex(scope.row)">{{scope.row.financingName}}</div>
                </template>
              </el-table-column>
              <el-table-column label="融资企业类型" prop="financingEnterpriseType" align="center" show-overflow-tooltip :resizable="false" :width="width150" />
              <el-table-column label="投资金额(元)" prop="managementFinancingAmount" align="right" header-align="center" show-overflow-tooltip sortable :resizable="false" :width="width150s">
                <template slot-scope="scope">{{scope.row.managementFinancingAmount | NumFormat}}</template>
              </el-table-column>
              <el-table-column label="融资利率" prop="managementRate" align="center" show-overflow-tooltip sortable :resizable="false" :width="width120" />
              <el-table-column label="放款日期" prop="managementArrivalDate" align="center" sortable show-overflow-tooltip :resizable="false" :width="width140" />
              <el-table-column label="还款日期" prop="managementRepaymentDate" align="center" sortable show-overflow-tooltip :resizable="false" :width="width140" />
              <el-table-column label="账款状态" prop="state" align="center" header-align="center" show-overflow-tooltip :resizable="false">
                <template slot-scope="scope">
                  <div v-if="scope.row.status == 5">已还款</div>
                  <div v-else>未还款</div>
                </template>
              </el-table-column>
              <el-table-column label="操 作" prop="operation" align="center" :resizable="false" :width="width180" fixed="right">
                <template slot-scope="scope">
                  <div class="table_oper flex_center">
                    <el-tooltip class="item_color_money" effect="light" content="还款详情" placement="top" v-if="scope.row.status == '5'">
                      <i class="iconfont icon-jieqinghuankuan" @click="invoicedetailDialog(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip class="item_color_money" effect="light" content="还款详情" placement="top" v-else-if="scope.row.status == '11' || scope.row.status == '4'">
                      <i class="iconfont icon-jieqinghuankuan" @click="outInvoicedetailDialog(scope.row)"></i>
                    </el-tooltip>
                    <el-tooltip class="item_color_money_complete" effect="light" content="还款详情" placement="top" disabled v-else>
                      <i class="iconfont icon-jieqinghuankuan"></i>
                    </el-tooltip>
                    <!-- <el-tooltip class="item_color_money" effect="light" content="还款详情" placement="top" v-else>
                      <i class="iconfont icon-jieqinghuankuan" @click="outInvoicedetailDialog(scope.row)"></i>
                    </el-tooltip> -->
                    <el-tooltip class="item_color_money" effect="light" content="贷款详情" placement="top">
                      <i class="iconfont icon-xiangqingye-35" @click="detailDialog(scope.row,scope.$index)"></i>
                    </el-tooltip>
                    <el-tooltip class="item_color" effect="light" content="服务" placement="top">
                      <i class="iconfont icon--fuwu-xianxing"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <Page :totalFont=true :total=page.total :currentPage=page.pageNo @onPageChange="onPageChange"></Page>
        </div>
      </div>
    </div>
    <div v-show="aa">
      <!-- <div class="second_return_icon" @click="returnButton()">
              <i class="iconfont icon-fanhui" ></i>
              融资企业详情
          </div> -->
      <Company ref="Company"></Company>
    </div>
    <!-- 贷款详情弹窗 -->
    <el-dialog title="贷款详情" :visible.sync="investmentDetailsDialog" center class="dialog_width500 dialog_add_edit_form width_1100" :close-on-click-modal="false">
      <div class="width_1100_left">
        <div class="add_invoice_message inner_common notXin">
          <el-form :model="financingForms" refs="financingForms">
            <el-form-item label="融资编号:">
              <el-input v-model="financingForms.financingNum" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资企业名称:">
              <el-input v-model="financingForms.financingName" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资企业类型:">
              <el-input v-model="financingForms.financingEnterpriseType" disabled></el-input>
            </el-form-item>
            <el-form-item label="投资金额:">
              <el-input v-model="financingForms.managementFinancingAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资利率:">
              <el-input v-model="financingForms.managementRate" disabled></el-input>
            </el-form-item>
            <el-form-item label="放款时间:">
              <el-input v-model="financingForms.managementArrivalDate" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款时间:">
              <el-input v-model="financingForms.managementRepaymentDate" disabled></el-input>
            </el-form-item>
            <el-form-item label="账款状态:">
              <el-input v-model="financingForms.state1" disabled></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="width_1100_right">
        <div class="add_invoice_message inner_common notXin">
          <div class="table_tit">申请融资发票详情:</div>
          <el-table class="dialog_table" :data="tableData0" stripe border style="width: 100%">
            <el-table-column label="发票编号" prop="invoiceNo" align="center" :resizable="false" show-overflow-tooltip :width="dialogidWidth" />
            <el-table-column label="发票日期" prop="invoiceDate" align="center" show-overflow-tooltip :resizable="false" />
            <el-table-column label="发票金额" prop="invoiceAmount" align="right" header-align="center" show-overflow-tooltip :resizable="false">
              <template slot-scope="scope">
                {{scope.row.invoiceAmount | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column label="发票账期" prop="accPeriod" align="center" show-overflow-tooltip :resizable="false" />
            <el-table-column label="账款到期日" prop="invoiceDateEnd" align="center" header-align="center" show-overflow-tooltip :resizable="false" :width="dialogWidth" />
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button1" type="primary" @click="investmentDetailsDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 已还款记录弹窗 -->
    <el-dialog title="还款记录" :visible.sync="invoiceDetailDialog" center class="dialog_width500 dialog_add_edit_form width_1000" :close-on-click-modal="false">
      <div class="waiting_signing">已还款</div>
      <el-form :model="invoiceForm" refs="invoiceForm">
        <div class="width_1000_left">
          <div class="add_invoice_message inner_common notXin el_form_font70">
            <el-form-item label="融资编号:">
              <el-input v-model="invoiceForm.financingNum" disabled></el-input>
            </el-form-item>
            <el-form-item label="资方名称:">
              <el-input v-model="invoiceForm.companyName" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资金额:">
              <el-input v-model="invoiceForm.managementFinancingAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资利率:">
              <el-input v-model="invoiceForm.managementRate" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资期限:">
              <el-input v-model="invoiceForm.financingTerm" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款金额:">
              <el-input v-model="invoiceForm.repaymentAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款时间:">
              <el-input v-model="invoiceForm.tradeTime" disabled></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="width_1000_right">
          <div class="add_invoice_message inner_common notXin el_form_font70">
            <el-form-item label="还款银行:">
              <el-input v-model="invoiceForm.repaymentBank" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款账号:">
              <el-input v-model="invoiceForm.repaymentAccount" disabled></el-input>
            </el-form-item>
            <el-form-item label="银行流水号:">
              <el-input v-model="invoiceForm.supplyBankSerialNumber" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款来源:">
              <el-input v-model="invoiceForm.repaymentSource" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款来源内容:">
              <el-input v-model="invoiceForm.repaymentSourceContent" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款凭证:">
              <ul class="invimg_list flex_wrap">
                <li v-for="(item,index) in invoiceForm.invImg" :key="index" class="flex_center">
                  <img :src="jpg + item">
                </li>
              </ul>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button1" type="primary" @click="invoiceDetailDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 未还款记录弹窗 -->
    <el-dialog title="还款记录" :visible.sync="outInvoiceDetailDialog" center class="dialog_width500 dialog_add_edit_form width_1000" :close-on-click-modal="false">
      <div class="waiting_signing">未还款</div>
      <el-form :model="invoiceForm" refs="invoiceForm">
        <div class="width_1000_left">
          <div class="add_invoice_message inner_common notXin el_form_font70">
            <el-form-item label="融资编号:">
              <el-input v-model="invoiceForm.financingNum" disabled></el-input>
            </el-form-item>
            <el-form-item label="资方名称:">
              <el-input v-model="invoiceForm.companyName" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资金额:">
              <el-input v-model="invoiceForm.managementFinancingAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资利率:">
              <el-input v-model="invoiceForm.managementRate" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资期限:">
              <el-input v-model="invoiceForm.financingTerm" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款金额:">
              <el-input v-model="invoiceForm.repaymentAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款时间:">
              <el-input v-model="invoiceForm.tradeTime" disabled></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="width_1000_right">
          <div class="add_invoice_message inner_common notXin el_form_font70">
            <el-form-item label="还款银行:">
              <el-input v-model="invoiceForm.repaymentBank" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款账号:">
              <el-input v-model="invoiceForm.repaymentAccount" disabled></el-input>
            </el-form-item>
            <el-form-item label="银行流水号:">
              <el-input v-model="invoiceForm.supplyBankSerialNumber" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款来源:">
              <el-input v-model="invoiceForm.repaymentSource" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款来源内容:">
              <el-input v-model="invoiceForm.repaymentSourceContent" disabled></el-input>
            </el-form-item>
            <el-form-item label="还款凭证:">
              <ul class="invimg_list flex_wrap">
                <li v-for="(item,index) in invoiceForm.invImg" :key="index" class="flex_center">
                  <img :src="jpg + item">
                </li>
              </ul>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="outInvoiceDetailDialog = false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="outInvoice()">确 认</el-button>
      </span>
    </el-dialog>
    <!-- 分期还款详情弹框 -->
    <el-dialog title="还款详情" :visible.sync="repaymentDetailDialog" center class="dialog_width600 dialog_width500" :close-on-click-modal="false">
      <div class="waiting_signing" v-if="this.Repaymentstatus == 5">已还款</div>
      <!-- <div class="waiting_signing" v-else-if="status == '4'">未还款</div>
      <div class="waiting_signing" v-else-if="status == '5'">已还款</div>
      <div class="waiting_signing" v-else-if="status == '12'">已收款</div> -->
      <div class="waiting_signing" v-else-if="this.Repaymentstatus == 11 || this.Repaymentstatus == 4">未还款</div>
      <div class="add_invoice_message inner_common notXin add_invoice_messagebt">
        <el-table class="dialog_table" :data="RepaymentDetails" stripe border style="width: 100%">
          <el-table-column class="text_overflow" label="还款金额" prop="repayAmount" align="center" :resizable="false" show-overflow-tooltip :width="dialogidWidth">
            <template slot-scope="scope">
              {{scope.row.repayAmount | NumFormat}}
            </template>
          </el-table-column>
          <el-table-column class="text_overflow" label="还款时间" prop="repayTime" align="center" show-overflow-tooltip :resizable="false" />
          <el-table-column class="text_overflow" label="还款银行" prop="repayBank" align="center" header-align="center" show-overflow-tooltip :resizable="false">
          </el-table-column>
          <el-table-column class="text_overflow" label="还款账号" prop="repayAccount" align="center" show-overflow-tooltip :resizable="false" />
          <el-table-column class="text_overflow" label="银行流水号" prop="repaySerialNum" align="center" header-align="center" show-overflow-tooltip :resizable="false" :width="dialogWidth" />
          <el-table-column class="text_overflow" label="还款凭证" prop="repayVouchers" align="center" header-align="center" show-overflow-tooltip :resizable="false" :width="dialogWidth">
            <template slot-scope="scope">
              <ul class="invimg_list flex_wrap tabimg">
                <li class="flex_center">
                  <img :src="jpg+scope.row.repayVouchers[0]" />
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分期还款未还清 -->
      <span slot="footer" class="dialog-footer" v-if="this.Repaymentstatus == 4">
        <el-button class="footer_button2" round @click="repaymentDetailDialog = false">关 闭</el-button>
        <el-button class="footer_button1 data_export" :disabled="Repaymentstatus == 4" type="primary">确 认</el-button>
      </span>
      <!-- 分期还款还清了没有点确认 -->
      <span slot="footer" class="dialog-footer" v-if="this.Repaymentstatus == 11">
        <el-button class="footer_button2" round @click="repaymentDetailDialog = false">关 闭</el-button>
        <el-button class="footer_button1" type="primary" @click="outInvoice()">确 认</el-button>
      </span>
      <!-- 分期还款还清了点了确认 -->
      <span slot="footer" class="dialog-footer" v-if="this.Repaymentstatus==5">
        <el-button class="footer_button1" type="primary" @click="repaymentDetailDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Company from '../../supplier/components/companyDetail.vue'
import { mixins1 } from "@/mixins/index";
import Page from "@/components/page";
import { getRepaymentDetails } from "@/api/supplier.js";
import { getManageInvestmentList, findCodeInfoByKey, getManageInvestmentInfoById, getFinancingComparisonById, findInvestmentCounts, showLoan, saveCollectionVoucher } from '@/api/investor.js'
export default {
  mixins: [mixins1],
  components: {
    Page, Company
  },
  data() {
    return {
      aa: false,
      list1: [
        {
          value: 0,
          name: "已回款总额",
        },
        {
          value: 0,
          name: "待回款总额",
        },
        {
          value: 0,
          name: "逾期总额",
        },
      ],
      list2: [
        {
          value: 0,
          name: "已回款总额",
        },
        {
          value: 0,
          name: "待回款总额",
        },
        {
          value: 0,
          name: "逾期总额",
        },
      ],
      list3: [
        {
          value: 0,
          name: "已还款单数",
        },
        {
          value: 0,
          name: "未还款单数",
        },
        {
          value: 0,
          name: "逾期单数",
        },
      ],
      financingForms: {
        financingNum: "",
        financingName: "",
        financingEnterpriseType: "",
        managementFinancingAmount: "",
        financingRate: "",
        lendTime: "",
        repaymentTime: "",
        state1: ''
      },
      financingForms1: {
        financingNum: "",
        financingName: "",
        financingEnterpriseType: "",
        state: "",
        lendTime: "",
        repaymentTime: "",
        pageNo: 1,
        pageSize: 8,
        lendStartTime: '',
        lendEndTime: '',
        repayStartTime: '',
        repayEndTime: '',
      },
      tableData: [
      ],
      tableData0: [
      ],
      tableData1: [
        {
          id: "00125398",
          data: '2021/10/20',
          money: "16900",
          accounting: '30',
          dataEnd: '2021/10/16'
        },
      ],
      tableData2: [
        {
          id: "01638556",
          data: '2021/10/19',
          money: "24900",
          accounting: '90',
          dataEnd: '2021/10/16'
        },
      ],
      tableData3: [
        {
          id: "02963294",
          data: '2021/9/10',
          money: "15158",
          accounting: '30',
          dataEnd: '2021/9/7'
        },
      ],
      tableData4: [
        {
          id: "21960922",
          data: '2021/8/10',
          money: "99000",
          accounting: '60',
          dataEnd: '2021/8/5'
        },
      ],
      invoiceForm: {
        financingNum: "",
        companyName: "",
        managementFinancingAmount: "",
        financingRate: "",
        financingTerm: "",
        repaymentAccount: '',
        repaymentDate: '',
        repaymentBank: '',
        repaymentNumber: '',
        bankSerialNumber: '',
        repaymentSource: '',
        repaymentSourceContent: '',
        invImg: [],
      },
      invoiceDetailDialog: false,
      outInvoiceDetailDialog: false,
      investmentDetailsDialog: false,
      classChange: false,
      supplierNum: 0,
      dialogidWidth: 94,
      dialogWidth: 100,
      coreEnterPriseNum: 0,
      page: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      companyState: [],
      accountsState: [],
      alreadyEnterPriseNum: 0,
      cnCompany: '',
      highInvestmentMoney: 0,
      lowInvestmentMoney: 0,
      averageInvestmentMoney: 0,
      averageInvestmentReturn: 0,
      loanTerm1: '',
      repaymentDetailDialog: false,
      RepaymentDetails: [],
      Repaymentstatus: 0,//判断余额是否为0，为0则true
      isRepayConfirm: false,//判断还款是否点击确认，点击了则true
    };
  },
  created() {
    this.tableWidth()
    this.resizeFn()
    this.getData()
    this.ficationList1()
  },
  methods: {
    searchInput(val) {
      val = val.replace(/[^\d]/g, '')
      this.financingForms1.financingNum = val
    },
    onPageChange(val) {
      this.page.pageNo = val
      this.getData()
    },
    async getData() {
      let datas = localStorage.getItem('userInfo')
      datas = JSON.parse(datas)
      this.userid = datas.userId
      datas = localStorage.getItem('cnCompany')
      this.cnCompany = datas
      if (this.financingForms1.lendTime != null) {
        if (this.financingForms1.lendTime[0]) {
          this.financingForms1.lendStartTime = this.financingForms1.lendTime.slice(',')[0]
          this.financingForms1.lendEndTime = this.financingForms1.lendTime.slice(',')[1]
        } else {
          this.financingForms1.lendStartTime = ''
          this.financingForms1.lendEndTime = ''
        }
      } else {
        this.financingForms1.lendStartTime = ''
        this.financingForms1.lendEndTime = ''
      }
      if (this.financingForms1.repaymentTime != null) {
        if (this.financingForms1.repaymentTime[0]) {
          this.financingForms1.repayStartTime = this.financingForms1.repaymentTime.slice(',')[0]
          this.financingForms1.repayEndTime = this.financingForms1.repaymentTime.slice(',')[1]
        } else {
          this.financingForms1.repayStartTime = ''
          this.financingForms1.repayEndTime = ''
        }
      } else {
        this.financingForms1.repayStartTime = ''
        this.financingForms1.repayEndTime = ''
      }
      let res = await getManageInvestmentList('pageNo=' + this.page.pageNo + '&pageSize=' + this.page.pageSize + '&ms=' + 2 + '&financingNum=' + this.financingForms1.financingNum +
        '&financingName=' + this.financingForms1.financingName + '&financingEnterpriseType=' + this.financingForms1.financingEnterpriseType + '&state=' + this.financingForms1.state +
        '&lendStartTime=' + this.financingForms1.lendStartTime + '&lendEndTime=' + this.financingForms1.lendEndTime + '&repayStartTime=' + this.financingForms1.repayStartTime +
        '&repayEndTime=' + this.financingForms1.repayEndTime)
      this.tableData = res.data.records
      this.page.total = res.data.total
      res = await findInvestmentCounts()
      this.list1[0].value = res.data.repayment_amount
      this.list1[1].value = res.data.revert_amount
      this.list1[2].value = res.data.overdue_amount
      this.list2[0].value = res.data.repayment_amount
      this.list2[1].value = res.data.revert_amount
      this.list2[2].value = res.data.overdue_amount
      this.supplierNum = res.data.sum_lent_amount
      this.list3[0].value = res.data.repayment_count
      this.list3[1].value = res.data.revert_count
      this.list3[2].value = res.data.overdue_count
      this.coreEnterPriseNum = res.data.sum_investment_count
      this.alreadyEnterPriseNum = res.data.repayment_count
      this.$nextTick(function () {
        this.riskEchart1(this.fontSize, this.echartsWidth);
      });
      this.highInvestmentMoney = res.data.max_investment_amount
      this.lowInvestmentMoney = res.data.min_investment_amount
      this.averageInvestmentMoney = res.data.avg_investment_amount
      this.averageInvestmentReturn = res.data.avg_return_amount
    },
    async ficationList1() {
      let res = await findCodeInfoByKey('ms=' + 28)
      this.companyState = res.data
      res = await findCodeInfoByKey('ms=' + 11)
      this.accountsState = res.data
    },
    detailIndex(row) {
      this.$refs.Company.getCompany(row.financingName)
      this.aa = true
      // this.top()
    },
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.companyName = 200;
        this.dialogWidth = 100;
        this.fontSize = 14;
        this.echartsWidth = 36;
        this.dialogidWidth = 85;
        this.width100 = 100
        this.width150s = 150
        this.width180 = 160
      } else {
        this.companyName = 140;
        this.dialogWidth = 69;
        this.fontSize = 10;
        this.echartsWidth = 24;
        this.dialogidWidth = 63
        this.width100 = 70
        this.width150s = 120
        this.width180 = 130
      }
      this.$nextTick(function () {
        this.riskEchart1(this.fontSize, this.echartsWidth);
      });
    },
    // 展开子表格
    onClickExpansion(row) {
      this.tableData.map((item) => {
        if (row.id != item.id) {
          item.isExpand = false;
          this.$refs.treeTable.toggleRowExpansion(item, false);
        }
      });
      row.isExpand = !row.isExpand;
      this.$refs.treeTable.toggleRowExpansion(row);
    },
    // 已还款详情
    async invoicedetailDialog(row) {
      if (row.isStage == 1) {
        let datadetail = await getRepaymentDetails('financingNum=' + row.financingNum)
        this.RepaymentDetails = datadetail.data
        console.log(datadetail)
        if (datadetail.data[datadetail.data.length - 1].chargeBalance == 0) {//还清了没有确认
          this.Repaymentstatus = 5
          console.log(this.Repaymentstatus)
        } else if (this.isRepayConfirm == true) {//还清了点了确认
          this.Repaymentstatus = 5
          console.log(this.Repaymentstatus)
        } else if (datadetail.data[datadetail.data.length - 1].chargeBalance != 0) {//没有还清
          this.Repaymentstatus = 4
          console.log(this.Repaymentstatus)
        }
        // if (datadetail.data[datadetail.data.length - 1].chargeBalance == 0) {
        //   this.Repaymentstatus = 5
        //   console.log(this.Repaymentstatus)
        // } else {
        //   console.log(1231231213123)
        //   this.Repaymentstatus = 11
        // }
        this.repaymentDetailDialog = true
        this.invoiceForm.id = row.id
        this.invoiceForm.managementId = datadetail.data[0].managerId
        this.invoiceForm.supplierId = datadetail.data[0].supplierId
        this.invoiceForm.financingNum = datadetail.data[0].financingNum
        console.log(row)
      } else {
        let datadetail = await getRepaymentDetails('financingNum=' + row.financingNum)
        console.log(datadetail)
        let res = await getFinancingComparisonById('id=' + row.id + '&financingNum=' + row.financingNum + '&manageId=' + row.managementId + '&supplierId=' + row.userCode)
        this.invoiceDetailDialog = true;
        this.invoiceForm = JSON.parse(JSON.stringify(row))
        // this.invoiceForm.invImg = res.data.repaymentVoucher
        this.invoiceForm.financingTerm = res.data.loanTerm
        this.invoiceForm.managementFinancingAmount = this.filter(
          JSON.parse(JSON.stringify(this.invoiceForm.managementFinancingAmount))
        );
        // this.invoiceForm.repaymentAmount = this.filter(this.invoiceForm.repaymentAmount)
        this.invoiceForm.companyName = this.cnCompany
        this.invoiceForm.repaymentAmount = this.filter(datadetail.data[0].repayAmount)
        this.invoiceForm.tradeTime = datadetail.data[0].repayTime
        this.invoiceForm.repaymentBank = datadetail.data[0].repayBank
        this.invoiceForm.repaymentAccount = datadetail.data[0].repayAccount
        this.invoiceForm.supplyBankSerialNumber = datadetail.data[0].repaySerialNum
        this.invoiceForm.repaymentSource = datadetail.data[0].repaySource
        this.invoiceForm.repaymentSourceContent = datadetail.data[0].repayContent
        this.invoiceForm.invImg = datadetail.data[0].repayVouchers
        if (this.invoiceForm.repaymentSource == '0') {
          this.invoiceForm.repaymentSource = '第一还款来源'
        }
        else if (this.invoiceForm.repaymentSource == '1') {
          this.invoiceForm.repaymentSource = '第二还款来源'
        }
      }
    },
    //未还款详情    isStage为1则是分期，为0则是不分期
    async outInvoicedetailDialog(row) {
      if (row.isStage == 1) {
        this.repaymentDetailDialog = true
        let datadetail = await getRepaymentDetails('financingNum=' + row.financingNum)
        this.RepaymentDetails = datadetail.data
        if (datadetail.data.length == 0) {//当选择分期还款后，供应商没有还款记录时
          this.Repaymentstatus = 4
        } else {
          if (datadetail.data[datadetail.data.length - 1].chargeBalance == 0) {//还清了没有确认
            this.Repaymentstatus = 11
          } else if (this.isRepayConfirm == true) {//还清了点了确认
            this.Repaymentstatus = 5
          } else if (datadetail.data[datadetail.data.length - 1].chargeBalance != 0) {//没有还清
            this.Repaymentstatus = 4
          }
        }
        this.invoiceForm.id = row.id
        this.invoiceForm.managementId = datadetail.data[0].managerId
        this.invoiceForm.supplierId = datadetail.data[0].supplierId
        this.invoiceForm.financingNum = datadetail.data[0].financingNum
      } else {
        let datadetail = await getRepaymentDetails('financingNum=' + row.financingNum)
        let res = await getFinancingComparisonById('id=' + row.id + '&financingNum=' + row.financingNum + '&manageId=' + row.managementId + '&supplierId=' + row.userCode)
        this.outInvoiceDetailDialog = true
        this.invoiceForm = JSON.parse(JSON.stringify(row))
        this.invoiceForm.financingTerm = res.data.loanTerm
        this.invoiceForm.managementFinancingAmount = this.filter(this.invoiceForm.managementFinancingAmount)
        this.invoiceForm.companyName = this.cnCompany
        this.invoiceForm.id = row.id
        this.invoiceForm.managerId = row.managementId
        this.invoiceForm.supplierId = row.userCode
        this.invoiceForm.repaymentAmount = this.filter(datadetail.data[0].repayAmount)
        this.invoiceForm.tradeTime = datadetail.data[0].repayTime
        this.invoiceForm.repaymentBank = datadetail.data[0].repayBank
        this.invoiceForm.repaymentAccount = datadetail.data[0].repayAccount
        this.invoiceForm.supplyBankSerialNumber = datadetail.data[0].repaySerialNum
        this.invoiceForm.repaymentSource = datadetail.data[0].repaySource
        this.invoiceForm.repaymentSourceContent = datadetail.data[0].repayContent
        this.invoiceForm.invImg = datadetail.data[0].repayVouchers
        if (this.invoiceForm.repaymentSource == '0') {
          this.invoiceForm.repaymentSource = '第一还款来源'
        }
        else if (this.invoiceForm.repaymentSource == '1') {
          this.invoiceForm.repaymentSource = '第二还款来源'
        }
      }
    },
    //未还款详情确认
    async outInvoice() {
      let form = {}
      form.id = this.invoiceForm.id
      form.managerId = this.invoiceForm.managementId
      form.supplierId = this.invoiceForm.supplierId
      form.financingNum = this.invoiceForm.financingNum
      let res = await saveCollectionVoucher(form)
      this.outInvoiceDetailDialog = false
      this.repaymentDetailDialog = false
      this.isRepayConfirm = true
      this.Repaymentstatus = 11
      this.getData()
    },
    // 贷款详情
    async detailDialog(row, index) {
      let res = await getManageInvestmentInfoById('Id=' + row.id)
      this.tableData0 = res.data.invoiceEntities
      this.financingForms = JSON.parse(JSON.stringify(row))
      this.financingForms.managementFinancingAmount = this.filter(this.financingForms.managementFinancingAmount)
      this.investmentDetailsDialog = true;
      if (this.financingForms.state1 == '0') {
        this.financingForms.state1 = '未确认'
      } else {
        this.financingForms.state1 = '已确认'
      }
    },
    buttonChange1() {
      this.classChange = false;
      this.list1 = this.list2;
      this.$nextTick(this.riskEchart1(this.fontSize, this.echartsWidth));
    },
    buttonChange2() {
      this.classChange = true;
      this.list1 = this.list3;
      this.$nextTick(this.riskEchart1(this.fontSize, this.echartsWidth));
    },
    // 搜索
    searchForm() {
      this.page.pageNo = 1
      this.getData()
    },
    // 重置
    resetButton() {
      this.$refs.financingForm.resetFields()
      this.page.pageNo = 1
      this.getData()
    },
  },
};
</script>

<style scoped>
  ::v-deep .tabimg li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px !important;
    height: 30px !important;
    margin-right: 0px !important;
  }
  ::v-deep .tabimg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::v-deep .dialog_width600 .el-dialog {
    width: 600px !important;
  }
  .apply_box_total_text_special .select {
    font-size: 38px;
  }
  .investor_workbench_table_title_total {
    padding: 20px 0;
    background: #edf2f5;
    box-shadow: 0px 0px 5px 0px rgba(9, 44, 55, 0.28) inset;
    border-radius: 10px;
    margin-bottom: 40px;
  }
  ::v-deep .dialog_table.el-table th > .cell {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ::v-deep .dialog_table.el-table th {
    font-size: 16px;
    height: 40px;
  }
  .dialog_table {
    /* max-height: 242px; */
    margin-bottom: 20px;
  }
  .table_tit {
    line-height: 40px;
    font-size: 14px;
    color: #162747;
  }
  ::v-deep .search_condition .data_item .el-form-item__label {
    min-width: 97px;
  }
  .workbench_table_title_total {
    background-color: transparent;
  }
  .workbench_table_title_total_tit {
    width: 225px;
  }
  .apply_box_top_left {
    width: 394px;
    height: 306px;
    padding-top: 30px;
    padding-left: 40px;
  }
  .class_change1 {
    background-image: url(../../../../assets/img/system/investor/echarts_tab.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
  }
  .class_change2 {
    background-image: url(../../../../assets/img/system/investor/echarts_tab1.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
  }

  .apply_box_total {
    display: flex;
    align-items: center;
    width: 300px;
    height: 108px;
    padding-top: 20px;
    padding-bottom: 24px;
    padding-left: 16px;
    background: rgba(237, 242, 245, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
    border-radius: 14px;
    cursor: pointer;
  }
  .apply_bottom {
    margin-bottom: 10px;
  }
  .echarts_box {
    height: 350px;
    margin-top: -40px;
  }
  .search_flex_box >>> .el-form-item {
    margin-bottom: 20px;
  }
  .search_flex_box >>> .el-form-item:last-child {
    margin-bottom: 0;
  }
  .search_flex_box >>> .el-form-item:nth-child(3) {
    margin-right: 0;
  }
  .search_condition {
    flex: 1;
  }
  .search_condition_right >>> .el-form-item {
    margin-right: 42px;
  }
  .second_return_icon {
    margin-left: 20px;
    cursor: pointer;
  }
  .second_return_icon i {
    margin-right: 24px;
    font-size: 20px;
  }
  .invimg_list li {
    float: left;
    width: 60px;
    height: 60px;
    margin-right: 12px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  .invimg_list img:hover {
    position: absolute;
    bottom: -1px;
    left: 0px;
    cursor: pointer;
    transform: scale(1);
  }
  @media screen and (max-width: 1664px) {
    .icon-jieqinghuankuan {
      font-size: 20px;
    }
    .apply_box_total_text_special .select {
      font-size: 26px;
    }
    .investor_workbench_table_title_total {
      padding: 13px 0;
      background: #edf2f5;
      box-shadow: 0px 0px 4px 0px rgba(9, 44, 55, 0.28) inset;
      border-radius: 7px;
      margin-bottom: 27px;
    }
    ::v-deep .dialog_table.el-table th > .cell {
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
    ::v-deep .dialog_table.el-table th {
      font-size: 12px;
      height: 27px;
    }
    .dialog_table {
      /* max-height: 242px; */
      margin-bottom: 13px;
    }
    .table_tit {
      line-height: 27px;
      font-size: 12px;
      color: #162747;
    }
    ::v-deep .search_condition .data_item .el-form-item__label {
      min-width: 82px;
    }
    .workbench_table_title_total {
      background-color: transparent;
    }
    .workbench_table_title_total_tit {
      width: 150px;
    }
    .apply_box_top_left {
      width: 263px;
      height: 204px;
      padding-top: 20px;
      padding-left: 27px;
    }
    .class_change1 {
      background-image: url(../../../../assets/img/system/investor/echarts_tab.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: top center;
    }
    .class_change2 {
      background-image: url(../../../../assets/img/system/investor/echarts_tab1.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: top center;
    }

    .apply_box_total {
      display: flex;
      align-items: center;
      width: 200px;
      height: 72px;
      padding-top: 13px;
      padding-bottom: 16px;
      padding-left: 11px;
      background: rgba(237, 242, 245, 0.2);
      box-shadow: 0px 3px 5px 0px rgba(0, 102, 101, 0.23);
      border-radius: 9px;
      cursor: pointer;
    }
    .apply_bottom {
      margin-bottom: 7px;
    }
    .echarts_box {
      height: 233px;
      margin-top: -27px;
    }
    .search_flex_box >>> .el-form-item {
      margin-bottom: 13px;
    }
    .search_flex_box >>> .el-form-item:last-child {
      margin-bottom: 0;
    }
    .search_flex_box >>> .el-form-item:nth-child(3) {
      margin-right: 0;
    }
    .search_condition {
      flex: 1;
    }
    .search_condition_right >>> .el-form-item {
      margin-right: 14px;
    }
    .second_return_icon {
      margin-left: 13px;
      cursor: pointer;
    }
    .second_return_icon i {
      margin-right: 16px;
      font-size: 13px;
    }
    .invimg_list li {
      float: left;
      width: 40px;
      height: 40px;
      margin-right: 8px;
      background: #f7fafc;
      border-radius: 3px;
      border: 1px solid #e1e7ea;
    }
    .invimg_list img:hover {
      position: absolute;
      bottom: -1px;
      left: 0px;
      cursor: pointer;
      transform: scale(1);
    }
  }
</style>